import React, { useEffect } from 'react';
import lottie from 'lottie-web';
import loader from '../util/lottie/loader.json';
import { Box } from '@material-ui/core';

const Loader = () => {
  useEffect(() => {
    lottie.loadAnimation({
      container: document.getElementById('loader'),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: loader,
    });
  }, []);

  return (
    <Box
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box id='loader' style={{ width: 100, height: 100 }} />
    </Box>
  );
};

export default Loader;
