const baseUrl = process.env.REACT_APP_BASE_URL;

export const headers = new Headers({
  'Content-type': 'application/json',
});

const apiGet = (url) =>
  fetch(`${baseUrl}/${url}`, { headers })
    .then((res) => {
      if (res.status === 401) {
        return Promise.reject(res);
      }
      return res.json();
    })
    .then((res) => {
      if (res.data.error) {
        return {
          error: res.data.error[0],
        };
      }
      return res;
    })
    .catch((err) => {
      console.log(err);
      if (err.status === 401) {
        localStorage.removeItem('persist:root');
        window.location.href = '/login';
      }
      return {
        error: 'Something went wrong',
      };
    });

const apiPost = (url, data) =>
  fetch(`${baseUrl}/${url}`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers,
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.data.error) {
        return {
          error: res.data.error[0],
        };
      }
      return res;
    })
    .catch((err) => {
      console.log(err);
      return {
        error: 'Something Went wrong!',
      };
    });

export { apiGet, apiPost };
