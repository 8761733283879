import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Box,
  IconButton,
  Tooltip,
  makeStyles,
  Divider,
} from '@material-ui/core';

import lqLiveLogo from '../assets/images/lq-live-logo.svg';
import DynamicIcon from '../util/DynamicIcon';
import { logout } from '../actions/loginActions';

const sidebarItems = [
  {
    title: 'Home',
    deeplink: '/dashboard',
    iconId: 1,
    filledIconId: 2,
  },
  {
    title: 'Groups',
    deeplink: '/groups',
    iconId: 3,
    filledIconId: 4,
  },
  {
    title: 'Training',
    deeplink: '/training',
    iconId: 6,
    filledIconId: 7,
  },
  {
    title: 'Performance',
    deeplink: '/performance',
    iconId: 8,
    filledIconId: 9,
  },
  {
    title: 'Referral',
    deeplink: '/referral',
    iconId: 10,
    filledIconId: 11,
  },
  {
    title: 'Leave',
    deeplink: '/leave',
    iconId: 12,
    filledIconId: 13,
  },
];
const sidebarItemsAffiliate = [
  {
    title: 'Referral',
    deeplink: '/referral',
    iconId: 10,
    filledIconId: 11,
  },
];
const useStyles = makeStyles(() => ({
  iconButton: {
    borderRadius: 8,
    padding: 8,
    '&:hover': {
      backgroundColor: '#f0e0ff',
    },
  },
}));

const Sidebar = (props) => {
  const [activeTab, setActiveTab] = useState();

  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();

  useEffect(() => {
    const tabIndex = (
      props.isAffiliate ? sidebarItemsAffiliate : sidebarItems
    ).findIndex((item) => item.deeplink === location.pathname);
    setActiveTab(tabIndex);
  }, [location]);

  return (
    <Box
      width='80px'
      height='100%'
      display='flex'
      flexDirection='column'
      py={2.5}
      alignItems='center'
      position='relative'
      style={{
        backgroundColor: 'white',
        boxShadow: '0px 0px 12px #aaa',
        zIndex: 10,
      }}
    >
      <img
        src={lqLiveLogo}
        alt='company-logo'
        width='50px'
        height='50px'
        onClick={() => {
          if (!props.isAffiliate) history.push('/dashboard');
        }}
        style={{ marginBottom: 28, cursor: 'pointer' }}
      />
      <Divider style={{ width: '50%', marginBottom: 20, height: 2 }} />
      <Box display='flex' flexDirection='column' alignItems='center' flex={1}>
        {(props.isAffiliate ? sidebarItemsAffiliate : sidebarItems || []).map(
          (item, index) => (
            <Box
              key={item.title + index}
              display='flex'
              alignItems='center'
              mb={2}
            >
              <Tooltip
                key={item.title + index}
                title={item.title}
                placement='right'
              >
                <IconButton
                  classes={{ root: classes.iconButton }}
                  color='secondary'
                  style={{
                    borderRadius: 8,
                    padding: 8,
                  }}
                  disableFocusRipple
                  onClick={() => history.push(item.deeplink)}
                >
                  <DynamicIcon
                    iconId={
                      activeTab === index ? item.filledIconId : item.iconId
                    }
                    style={{ fontSize: 28, color: '#240046' }}
                  />
                </IconButton>
              </Tooltip>
              {activeTab === index && (
                <Box
                  style={{
                    backgroundColor: '#240046',
                    position: 'absolute',
                    right: 0,
                    height: 24,
                    width: 5,
                    borderRadius: 2,
                  }}
                />
              )}
            </Box>
          )
        )}
      </Box>
      <Divider style={{ width: '50%', marginBottom: 20, height: 2 }} />
      <Tooltip title='Logout' placement='right'>
        <IconButton
          classes={{ root: classes.iconButton }}
          style={{ borderRadius: 8 }}
          disableFocusRipple
          onClick={props.logout}
        >
          <DynamicIcon iconId={5} style={{ fontSize: 28, color: '#240046' }} />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  isAffiliate: state.loginReducer?.userDetails?.role === 'affiliate',
});

export default connect(mapStateToProps, { logout })(Sidebar);
