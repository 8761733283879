import React, { Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import * as Sentry from '@sentry/react';

import theme from './globalTheme';
import { headers } from './util/api';
import Structure from './components/Structure';
import Loader from './components/Loader';

const HomePage = lazy(() => import('./components/HomePage/HomePage'));
const Login = lazy(() => import('./components/Login'));
const Classroom = lazy(() => import('./components/Classroom/Classroom'));
const Dashboard = lazy(() => import('./components/Dashboard/Dashboard'));
const Groups = lazy(() => import('./components/Groups/Groups'));
const Session = lazy(() => import('./components/Session/Session'));
const Student = lazy(() => import('./components/Student/Student'));
const Training = lazy(() => import('./components/Training/Training'));
const Quiz = lazy(() => import('./components/Quiz/Quiz'));
const ActivityPage = lazy(() =>
  import('./components/ActivityPage/ActivityPage')
);
const PerformancePage = lazy(() =>
  import('./components/PerformancePage/PerformacePage')
);
const PerformanceDetailsPage = lazy(() =>
  import('./components/PerformanceDetailsPage/PerformanceDetailsPage')
);
const Referral = lazy(() => import('./components/Referral/Referral'));
const LeavePage = lazy(() => import('./components/LeavePage/LeavePage'));

const PrivateRoute = (props) => {
  const { isLoggedIn, sessionToken, path, component, userId } = props;

  if (isLoggedIn) {
    Sentry.setUser({ id: userId });
    headers.set('Session-Token', sessionToken);
    return (
      <Structure>
        <Route exact path={path} component={component} />
      </Structure>
    );
  }
  return <Redirect to='/login' />;
};

const App = (props) => {
  const { isLoggedIn, sessionToken, userId } = props;

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Suspense fallback={<Loader />}>
          <Switch>
            <Route exact path='/' component={HomePage} />
            <Route exact path='/login' component={Login} />
            <PrivateRoute
              exact
              path='/dashboard'
              component={Dashboard}
              {...{ isLoggedIn, sessionToken, userId }}
            />
            <PrivateRoute
              exact
              path='/groups'
              component={Groups}
              {...{ isLoggedIn, sessionToken, userId }}
            />
            <PrivateRoute
              exact
              path='/session'
              component={Session}
              {...{ isLoggedIn, sessionToken, userId }}
            />
            <PrivateRoute
              exact
              path='/student'
              component={Student}
              {...{ isLoggedIn, sessionToken, userId }}
            />
            <PrivateRoute
              exact
              path='/training'
              component={Training}
              {...{ isLoggedIn, sessionToken, userId }}
            />
            <PrivateRoute
              exact
              path='/quiz'
              component={Quiz}
              {...{ isLoggedIn, sessionToken, userId }}
            />
            <PrivateRoute
              exact
              path='/classroom'
              component={Classroom}
              {...{ isLoggedIn, sessionToken, userId }}
            />
            <PrivateRoute
              exact
              path='/activity'
              component={ActivityPage}
              {...{ isLoggedIn, sessionToken, userId }}
            />
            <PrivateRoute
              exact
              path='/performance'
              component={PerformancePage}
              {...{ isLoggedIn, sessionToken, userId }}
            />
            <PrivateRoute
              exact
              path='/performance-details'
              component={PerformanceDetailsPage}
              {...{ isLoggedIn, sessionToken, userId }}
            />
            <PrivateRoute
              exact
              path='/referral'
              component={Referral}
              {...{ isLoggedIn, sessionToken, userId }}
            />
            <PrivateRoute
              exact
              path='/leave'
              component={LeavePage}
              {...{ isLoggedIn, sessionToken, userId }}
            />
          </Switch>
        </Suspense>
      </BrowserRouter>
    </MuiThemeProvider>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.loginReducer.isLoggedIn,
  sessionToken: state.loginReducer.userDetails.session_token,
  userId: state.loginReducer.userDetails.user_id,
});

export default connect(mapStateToProps, null)(App);
