import React from 'react';
import {
  HomeOutlined,
  PeopleAltOutlined,
  People,
  Help,
  ExitToApp,
  Home,
  Assessment,
  AssessmentOutlined,
  Book,
  BookOutlined,
  Redeem,
  RedeemTwoTone,
  EventBusy,
  EventBusyOutlined
} from '@material-ui/icons';

import coinsBox from '../assets/images/coinsBox.svg';
import rankBox from '../assets/images/rankBox.svg';
import starBox from '../assets/images/starBox.png';
import stickerBox from '../assets/images/stickerBox.svg';
import proficiencyScoreBox from '../assets/images/proficiencyScoreBox.svg';

const icon = {
  1: HomeOutlined,
  2: Home,
  3: PeopleAltOutlined,
  4: People,
  5: ExitToApp,
  6: BookOutlined,
  7: Book,
  8: AssessmentOutlined,
  9: Assessment,
  10: Redeem,
  11: RedeemTwoTone,
  12: EventBusy,
  13: EventBusyOutlined,
  100: Help,
};

const iconPath = {
  Score: coinsBox,
  Rank: rankBox,
  Stickers: stickerBox,
  'Earned Stars': starBox,
  Stars: starBox,
  'Proficiency Score': proficiencyScoreBox,
};

const DynamicIcon = ({ iconId, imageId, style }) => {
  if (iconId) {
    const IconComponent = icon[iconId] || 100;
    return <IconComponent style={style} />;
  }
  return <img src={iconPath[imageId]} alt={imageId} style={style} />;
};

export default DynamicIcon;
