import React from 'react';
import { makeStyles, Popover, Box, Divider } from '@material-ui/core';
import { DoneAll } from '@material-ui/icons';
import moment from 'moment';

const useStyles = makeStyles(() => ({
  outerContainer: {
    backgroundColor: 'white',
    borderRadius: 8,
    fontSize: '1vw',
    color: 'black',
    fontWeight: 600,
    display: 'flex',
    flexDirection: 'column',
  },
  announcementHeader: {
    fontSize: '1.25vw',
    fontWeight: 700,
    padding: '1.5vw 1.5vw 1vw 1.5vw',
  },
  innerContainer: {
    maxWidth: '30vw',
    maxHeight: '60vh',
    overflow: 'auto',
    width: 'fit-content',
    height: 'fit-content',
    minWidth: '25vw',
    minHeight: '40vh',
    gap: '0.8vw',
    padding: '0.5vw 1.5vw 1.5vw 1.5vw',
    display: 'flex',
    flexDirection: 'column',
  },
  readTime: {
    fontSize: '0.9vw',
    color: 'rgb(125 125 125)',
    gap: 5,
    display: 'flex',
    alignItems: 'center',
  },
  announcementDetails: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5vw',
  },
}));

const ReadAnnouncementsPopup = (props) => {
  const { announcementList, onClose, announcementRef } = props;
  const classes = useStyles();

  return (
    <Popover
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      anchorEl={announcementRef.current}
      anchorReference={announcementRef.current}
      open={announcementList ? true : false}
      onClose={onClose}
    >
      <Box className={classes.outerContainer}>
        <div className={classes.announcementHeader}>Announcements</div>
        <Box className={classes.innerContainer}>
          {announcementList.length ? (
            announcementList?.map((announcementData, index) => (
              <>
                <Box className={classes.announcementDetails} key={index}>
                  <div>{announcementData.description}</div>
                  <div className={classes.readTime}>
                    <DoneAll style={{ fontSize: '1.25vw' }} />
                    {moment(announcementData?.read_time).format(
                      'MMM DD YYYY, HH:mm A'
                    )}
                  </div>
                </Box>
                {index < announcementList.length - 1 ? (
                  <Divider style={{ height: 2 }} />
                ) : null}
              </>
            ))
          ) : (
            <div className='margin-auto'>No Past Announcements</div>
          )}
        </Box>
      </Box>
    </Popover>
  );
};

export default ReadAnnouncementsPopup;
