import { createTheme } from '@material-ui/core';

const theme = createTheme({
  typography: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
  },
  palette: {
    primary: {
      main: '#240046',
    },
    secondary: {
      main: '#BF5AF2',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          height: '100vh',
          width: '100vw',
          fontSize: 16,
          backgroundColor: 'white',
        },
        '#root': {
          height: '100vh',
        },
        'input:-webkit-autofill': {
          '-webkit-text-fill-color': '#7b7f85 !important',
          '-webkit-box-shadow': '0 0 0px 1000px white inset',
        },
        '*::-webkit-scrollbar': { width: 8, height: 8 },
        '*::-webkit-scrollbar-thumb': {
          background: '#c3c3c3',
          borderRadius: 20,
        },
        '*::-webkit-scrollbar-thumb:hover': {
          backgroundColor: '#848484',
        },
        video: {
          objectFit: 'contain !important',
        },
        '.react-calendar': {
          background: 'white',
          fontFamily: 'Arial, Helvetica, sans-serif',
          lineHeight: '1.125em',
        },
        '.react-calendar__navigation__prev2-button': {
          display: 'none',
        },
        '.react-calendar__navigation__prev-button': {
          position: 'absolute',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          right: 40,
          width: 32,
          height: 32,
          borderRadius: '50%',
          minWidth: 'unset !important',
          border: '1px solid gray !important',
          fontSize: 24,
        },
        '.react-calendar__navigation__next-button': {
          position: 'absolute',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          right: 0,
          minWidth: 'unset !important',
          width: 32,
          height: 32,
          borderRadius: '50%',
          border: '1px solid gray !important',
          fontSize: 24,
        },
        '.react-calendar__navigation__next2-button': {
          display: 'none',
        },
        '.react-calendar__viewContainer': {
          height: 'calc(100% - 44px)',
        },
        '.react-calendar__month-view': {
          height: '100%',
        },
        '.react-calendar__month-view div': {
          height: '100%',
        },
        '.react-calendar__month-view__days': {
          paddingRight: 16,
          flexWrap: 'unset !important',
          height: 'calc(100% - 54px) !important',
          display: 'grid !important',
          rowGap: 8,
          gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr',
          overflowY: 'auto',
          overflowX: 'hidden',
        },
        '.react-calendar button': {
          justifySelf: 'center',
          alignSelf: 'center',
          maxWidth: 'unset !important',
          flexBasis: 'unset !important',
          margin: '0px !important',
          border: 0,
          outline: 'none',
          overflow: 'unset !important',
        },
        '.react-calendar button:enabled:hover': {
          cursor: 'pointer',
        },
        '.react-calendar__navigation': {
          position: 'relative',
          height: '44px',
          marginBottom: 0,
          display: 'flex',
          alignItems: 'center',
          marginRight: 16,
        },
        '.react-calendar__navigation__label': {
          flexGrow: 'unset !important',
          fontSize: 18,
          fontWeight: 600,
          color: '#51565b',
        },
        '.react-calendar__navigation button': {
          minWidth: '44px',
          background: 'none',
        },
        '.react-calendar__navigation button:enabled:hover, .react-calendar__navigation button:enabled:focus':
          {
            backgroundColor: '#ccc',
          },
        '.react-calendar__month-view__weekdays': {
          display: 'grid !important',
          marginRight: 16,
          gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr',
          textAlign: 'center',
          textTransform: 'uppercase',
          fontWeight: 'bold',
          fontSize: '0.75em',
          height: 'unset !important',
        },
        '.react-calendar__month-view__weekdays__weekday': {
          maxWidth: 'unset !important',
          flexBasis: 'unset !important',
          padding: '18px 0px',
          fontSize: 14,
          color: '#6d6d6d',
        },
        'abbr[title]': {
          textDecoration: 'none !important',
        },
        '.react-calendar__month-view__weekNumbers': {
          fontWeight: 'bold',
        },
        '.react-calendar__month-view__weekNumbers .react-calendar__tile': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: '0.75em',
          padding: 'calc(0.75em / 0.75) calc(0.5em / 0.75)',
        },
        '.react-calendar__month-view__days__day--neighboringMonth': {
          color: '#989898',
        },
        '.react-calendar__year-view .react-calendar__tile, .react-calendar__decade-view .react-calendar__tile, .react-calendar__century-view .react-calendar__tile':
          {
            padding: '2em 0.5em',
          },
        '.react-calendar__tile': {
          maxWidth: '100%',
          textAlign: 'center',
          padding: '0.75em 0.5em',
          background: 'none',
        },
        '.react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus':
          {
            backgroundColor: '#ccc',
          },
        '.react-calendar__tile--now': {
          background: '#c78cff',
        },
        '.react-calendar__tile--now:enabled:hover, .react-calendar__tile--now:enabled:focus':
          {
            background: '#b160ff',
          },
        '.react-calendar__tile--hasActive': {
          background: '#690cbfad',
          color: 'white',
        },
        '.react-calendar__tile--hasActive:enabled:focus': {
          background: '#690cbfad',
        },
        '.react-calendar__tile--hasActive:enabled:hover': {
          background: '#640cb5cf',
        },
        '.react-calendar__tile--active, .react-calendar__tile--active:enabled:focus':
          {
            background: '#3C096C',
            color: 'white',
          },
        '.react-calendar__tile--active:enabled:hover': {
          background: '#3c096cc2',
        },
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',
      },
    },
  },
});

export default theme;
