import { combineReducers } from 'redux';
import loginReducer from './loginReducer';
import classroomReducer from './classroomReducer';

const rootReducer = combineReducers({
  loginReducer,
  classroomReducer,
});

export default (state, action) =>
  rootReducer(action.type === 'LOGOUT' ? undefined : state, action);
