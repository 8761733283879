import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import 'moment-duration-format';
import {
  Box,
  Toolbar,
  Typography,
  Avatar,
  IconButton,
} from '@material-ui/core';
import { get } from 'lodash';

import { updateClassroomTimer } from '../actions/classroomActions';
import { Timer, Notifications } from '@material-ui/icons';

import giftImg from '../assets/images/gift.png';
import { apiGet } from '../util/api';
import ReadAnnouncementsPopup from './Dashboard/helperComponents/ReadAnnouncementsPopup';

let timerInterval;
let initialRender = true;

const Header = (props) => {
  const [headerTitle, setHeaderTitle] = useState();
  const [announcementList, setAnnouncementList] = useState(false);

  const { userName, elapsedTime, classroomTimer, sessionEnded, userId } = props;
  const location = useLocation();
  const history = useHistory();

  const sessionEndedRef = useRef();
  const announcementRef = useRef();

  useEffect(() => {
    const path = location.pathname.split('/')[1];
    let titleWords = path.split('-');
    titleWords = titleWords.map(
      (word) => word[0].toUpperCase() + word.slice(1)
    );
    setHeaderTitle(titleWords.join(' '));

    if (timerInterval) clearInterval(timerInterval);
  }, [location]);

  useEffect(() => {
    if (
      location.pathname.indexOf('/classroom') !== -1 &&
      initialRender === false
    ) {
      timerInterval = setInterval(() => {
        if (sessionEndedRef.current) {
          clearInterval(timerInterval);
          return;
        }
        props.updateClassroomTimer();
      }, 1000);
    } else {
      initialRender = false;
    }
  }, [elapsedTime]);

  useEffect(() => {
    sessionEndedRef.current = sessionEnded;
  }, [sessionEnded]);

  function fetchAnnouncementList() {
    apiGet(`v1/teacher/${userId}/announcement/list/`).then((res) => {
      if (res.data) {
        const announcementData = res.data.results.read;
        setAnnouncementList(announcementData);
      }
    });
  }

  return (
    <Toolbar style={{ boxShadow: '0px 0px 6px #ccc' }}>
      <Box
        width='100%'
        display='flex'
        justifyContent='space-between'
        alignItems='center'
      >
        <Typography variant='h5' style={{ fontWeight: 600 }}>
          {headerTitle}
        </Typography>
        <Box
          display='inherit'
          justifyContent='inherit'
          alignItems='inherit'
          style={{ gap: 16 }}
        >
          <IconButton
            size='small'
            style={{
              position: 'relative',
              alignItems: 'center',
              cursor: 'pointer',
              display: 'flex',
              color: '#3c096c',
            }}
            onClick={() => {
              if (announcementList) setAnnouncementList(false);
              else fetchAnnouncementList();
            }}
            ref={announcementRef}
          >
            <Notifications />
          </IconButton>
          {announcementList ? (
            <ReadAnnouncementsPopup
              announcementList={announcementList}
              onClose={() => setAnnouncementList(false)}
              announcementRef={announcementRef}
            />
          ) : null}
          {location.pathname.indexOf('/classroom') !== -1 && (
            <Box
              px={2}
              py={1}
              display='flex'
              alignItems='center'
              style={{ backgroundColor: '#3C096C', borderRadius: 8 }}
            >
              <Timer
                style={{ marginRight: 12, color: '#BF5AF2', fontSize: 20 }}
              />
              <Typography style={{ lineHeight: 1, color: 'white' }}>
                {classroomTimer < 0 && 'Starting in - '}
                {moment
                  .duration(Math.abs(classroomTimer), 'seconds')
                  .format('HH : mm : ss', { trim: false })}
              </Typography>
            </Box>
          )}
          {location.pathname.indexOf('/activity') === -1 &&
            location.pathname.indexOf('/classroom') === -1 &&
            location.pathname.indexOf('/referral') === -1 && (
              <Box
                style={{
                  position: 'relative',
                  padding: '8px 12px',
                  background: '#3c096c',
                  borderRadius: 6,
                  color: 'white',
                  alignItems: 'center',
                  cursor: 'pointer',
                  display: 'flex',
                }}
                onClick={() => history.push('/referral')}
              >
                <img
                  src={giftImg}
                  alt='gift'
                  style={{ marginRight: 10, width: 20 }}
                />
                <div style={{ fontSize: 16, whiteSpace: 'nowrap' }}>
                  My Referrals
                </div>
                <div
                  style={{
                    position: 'absolute',
                    top: -10,
                    right: 10,
                    fontSize: 7,
                    padding: '2px 6px',
                    borderRadius: 4,
                    background:
                      'linear-gradient(270deg, #FF9F0A 0%, #FF270A 107.95%)',
                    border: '1.5px solid white',
                    fontWeight: 800,
                  }}
                >
                  NEW
                </div>
              </Box>
            )}
          <Box display='flex' alignItems='center' style={{ gap: 12 }}>
            <Avatar style={{ backgroundColor: '#3c096c', color: 'white' }}>
              {userName[0]}
            </Avatar>
            <Typography style={{ fontWeight: 600 }}>{userName}</Typography>
          </Box>
        </Box>
      </Box>
    </Toolbar>
  );
};

const mapStateToProps = (state) => ({
  userName: state.loginReducer.userDetails.name,
  elapsedTime: get(
    state.classroomReducer.sessionDetails,
    'session_details.elapsed_time'
  ),
  classroomTimer: get(state, 'classroomReducer.classroomTimer', 0),
  sessionEnded: get(state.classroomReducer, 'sessionEnded'),
  userId: state.loginReducer.userDetails.user_id,
});

export default connect(mapStateToProps, { updateClassroomTimer })(Header);
