export const STORE_SESSION_DETAILS = 'STORE_SESSION_DETAILS';
export const STORE_LEADERBOARD_DETAILS = 'STORE_LEADERBOARD_DETAILS';
export const STORE_ACTIVITIES_DETAILS = 'UPDATE_ACTIVITIES_DETAILS';
export const UPDATE_CLASSROOM_TIMER = 'UPDATE_CLASSROOM_TIMER';
export const RESET_CLASSROOM_DATA = 'RESET_CLASSROOM_DATA';
export const UPDATE_SESSION_END = 'UPDATE_SESSION_END';
export const SET_SESSION_SDK = 'SET_SESSION_SDK';
export const SET_VIRTUAL_BKG = 'SET_VIRTUAL_BKG';

export const STORE_LOGIN_DETAILS = 'STORE_LOGIN_DETAILS';
export const UPDATE_ISLOGGEDIN = 'UPDATE_ISLOGGEDIN';
export const LOGOUT = 'LOGOUT';
