import {
  STORE_SESSION_DETAILS,
  STORE_LEADERBOARD_DETAILS,
  STORE_ACTIVITIES_DETAILS,
  UPDATE_CLASSROOM_TIMER,
  UPDATE_SESSION_END,
  RESET_CLASSROOM_DATA,
  SET_SESSION_SDK,
  SET_VIRTUAL_BKG,
} from '../actions/types';

const initialState = {
  classroomTimer: 0,
  sessionDetails: undefined,
  leaderboardDetails: undefined,
  activitiesDetails: undefined,
  sessionEnded: false,
  sessionSdk: 'ag',
  isVirtualBkg: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case STORE_SESSION_DETAILS:
      return {
        ...state,
        sessionDetails: action.payload,
      };
    case STORE_LEADERBOARD_DETAILS:
      return {
        ...state,
        leaderboardDetails: action.payload,
      };
    case STORE_ACTIVITIES_DETAILS:
      return {
        ...state,
        activitiesDetails: action.payload,
      };
    case UPDATE_CLASSROOM_TIMER:
      return {
        ...state,
        classroomTimer: action.payload,
      };
    case UPDATE_SESSION_END:
      return {
        ...state,
        sessionEnded: true,
      };
    case SET_SESSION_SDK:
      return {
        ...state,
        sessionSdk: action.payload,
      };
    case SET_VIRTUAL_BKG:
      return {
        ...state,
        isVirtualBkg: action.payload,
      };
    case RESET_CLASSROOM_DATA:
      return initialState;
    default:
      return state;
  }
};
