import { STORE_LOGIN_DETAILS, UPDATE_ISLOGGEDIN } from '../actions/types';

const initialState = {
  userDetails: {},
  isLoggedIn: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case STORE_LOGIN_DETAILS:
      return {
        ...state,
        userDetails: action.payload,
      };
    case UPDATE_ISLOGGEDIN:
      return {
        ...state,
        isLoggedIn: action.payload,
      };
    default:
      return state;
  }
};
