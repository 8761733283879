import React from 'react';
import { useLocation } from 'react-router-dom';
import { Box } from '@material-ui/core';

import Header from './Header';
import Sidebar from './Sidebar';

const Structure = (props) => {
  const hideSidebar = ['classroom', 'activity', 'quiz'];
  const location = useLocation();
  const { children } = props;

  return (
    <Box display='flex' width='100%' height='100%'>
      {!hideSidebar.includes(location.pathname.split('/')[1]) && <Sidebar />}
      <Box
        display='flex'
        flexDirection='column'
        style={{
          backgroundColor: 'white',
          color: '#182c4e',
          width: !hideSidebar.includes(location.pathname.split('/')[1])
            ? 'calc(100% - 80px)'
            : '100%',
        }}
      >
        {location.pathname !== '/quiz' && <Header />}
        <Box flex={1} style={{ overflowY: 'auto', overflowX: 'hidden' }}>
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default Structure;
