import { STORE_LOGIN_DETAILS, UPDATE_ISLOGGEDIN, LOGOUT } from './types';

export const storeLoginDetails = (data) => ({
  type: STORE_LOGIN_DETAILS,
  payload: data,
});

export const updateIsLoggedIn = (data) => ({
  type: UPDATE_ISLOGGEDIN,
  payload: data,
});

export const logout = () => ({ type: LOGOUT });
