import {
  STORE_SESSION_DETAILS,
  STORE_LEADERBOARD_DETAILS,
  STORE_ACTIVITIES_DETAILS,
  UPDATE_CLASSROOM_TIMER,
  RESET_CLASSROOM_DATA,
  UPDATE_SESSION_END,
  SET_SESSION_SDK,
  SET_VIRTUAL_BKG,
} from './types';

export const storeSessionDetails = (data) => ({
  type: STORE_SESSION_DETAILS,
  payload: data,
});

export const storeLeaderboardDetails = (data) => ({
  type: STORE_LEADERBOARD_DETAILS,
  payload: data,
});

export const storeActivitiesDetails = (data) => ({
  type: STORE_ACTIVITIES_DETAILS,
  payload: data,
});

export const updateClassroomTimer = (time) => (dispatch, store) => {
  const { classroomTimer } = store()?.classroomReducer;
  dispatch({
    type: UPDATE_CLASSROOM_TIMER,
    payload: time || classroomTimer + 1,
  });
};

export const updateSessionEnd = () => ({
  type: UPDATE_SESSION_END,
});

export const resetClassroomData = () => ({
  type: RESET_CLASSROOM_DATA,
});

export const setSessionSdk = (sdk) => ({
  type: SET_SESSION_SDK,
  payload: sdk
});

export const setVirtualBkg = (value) => ({
  type: SET_VIRTUAL_BKG,
  payload: value,
});
